<template>
  <div style="height:100vh">
    <div v-if="isLoading" class="spinnerstyle">
      <Spinner />
    </div>
    <div class="userlogin-main-container d-flex align-items-center">
      <div class="userlogin-left-container"></div>
      <div class="userlogin-right-container position-relative">
        <div class="userlogin-credentials-container">
          <div class="ulcc-head">
            Get started
          </div>
          <div class="ulcc-subhead">
            Sign in to start the assessment
          </div>

          <div class="mt-5 userlogin-inputs">
            <div class="candidate-inputField position-relative">
              <b-form-input
                v-model.trim="$v.name.$model"
                :class="{ error: $v.name.$error }"
                @keyup.enter="onSave()"
                id="candidatename"
                placeholder=" "
              ></b-form-input>
              <label for="candidatename" class="ul-input-labelbox"
                >User Name</label
              >
              <div
                class="userlogin-validation"
                v-if="$v.name.$dirty && !$v.name.required"
              >
                Required
              </div>
              <div
                class="userlogin-validation"
                v-if="$v.name.required && !$v.name.alpha"
              >
                Enter a valid Name
              </div>
              <div class="userlogin-validation" v-if="!$v.name.maxLength">
                It should not exceed 64 characters
              </div>
            </div>
            <div class="mt-3 candidate-inputField position-relative">
              <b-form-input
                id="candidateEmail"
                ref="candmail"
                v-model.trim="$v.email.$model"
                :class="{ error: $v.email.$error }"
                @keyup.enter="onSave()"
                placeholder=" "
              ></b-form-input>
              <label for="candidateEmail" class="ul-input-labelbox"
                >Email</label
              >
              <div
                class="userlogin-validation"
                v-if="$v.email.$dirty && !$v.email.required"
              >
                Required
              </div>
              <div
                class="userlogin-validation"
                v-if="$v.email.required && !$v.email.email"
              >
                Enter a valid Email Id
              </div>
              <div class="userlogin-validation" v-if="!$v.email.maxLength">
                It should not exceed 64 characters
              </div>
            </div>
          </div>
          <div
            class="button_slide mt-5 position-relative pointer slide_right w-100 d-flex align-items-center justify-content-center"
            @click="onSave()"
          >
            Continue
            <div class="ml-2">
              <img src="../assets/newui-img/rightarrow.svg" alt />
            </div>
          </div>
          <div
            class="ulcc-copyright w-100 d-flex align-items-end justify-content-center"
          >
            Copyrights
            <img
              src="../assets/newui-img/copyright.svg"
              class=""
              alt
            />2024 All rights reserved.
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="expirytestmodalLap"
      size="md"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div
        @click="$bvModal.hide('expirytestmodalLap')"
        class="modalcloseicon pointer"
      >
        <img class="rotate-on-hover" src="../assets/newimgs/closeicon.svg" alt="" />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../assets/newui-img/alertimage.svg" alt="" />
        </div>
        <div class="newmodal-body-content mt-4">
          {{ toastMessage }}
        </div>
        <div class="bluebtns mt-5">
          <b-button
            variant="primary"
            class="px-5"
            @click="$bvModal.hide('expirytestmodalLap')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import studentLogin from "../mixins/studentLogin";

export default {
  name: "StudentLogin",
  mixins: [studentLogin],
};
</script>

<style>
.button_slide {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px;
  background: #498ac1;
  border: 1px solid #498ac1;
  display: inline-block;
  box-shadow: inset 0 0 0 0 #498ac1;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.slide_right {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #20639b;
  transition: box-shadow 2s ease, background 2s ease;
}
.slide_right:hover {
  box-shadow: inset 100vw 0 0 0 #20639b;
}
.candidate-inputField label {
  position: absolute;
  padding-left: 5px;
  top: 10px;
  cursor: text;
  margin-left: 1px;
}
.candidate-inputField input:focus + label,
.candidate-inputField input:not(:placeholder-shown) + label {
  opacity: 1;
  transform: translateY(-21px) translateX(8px);
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
  margin-left: -2px;
}
.candidate-inputField input:focus {
  background: #ffffff;
  border: 1px solid #d0d5dd;
}
.candidate-inputField input {
  width: 100%;
  padding-left: 10px;
}
.candidate-inputField label,
.candidate-inputField input {
  transition: all 0.3s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: .5;
}
.userlogin-main-container {
  height: 100vh;
}
.userlogin-credentials-container {
  height: 85%;
  position: absolute;
  top: 50px;
  left: -70%;
  width: 145%;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.26);
  border-radius: 16px;
  padding: 40px;
}
.userlogin-right-container {
  width: 25%;
  height: 100%;
  background-image: url("../assets/newui-img/userloginrightimage.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.userlogin-left-container {
  width: 75%;
  height: 100%;
  background-image: url("../assets/newui-img/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.ulcc-head {
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: 37px;
  color: #464646;
}
.ulcc-subhead {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #3a3c40;
}
.ulcc-copyright {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #929cac;
  position: absolute;
  bottom: 1%;
  left: 4%;
}
.candidate-inputField .form-control {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  padding: 7px 12px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  opacity:1;
}
.ul-input-labelbox {
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #667085;
}
.userlogin-validation {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #dc143c;
}
</style>

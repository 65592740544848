<template>
  <div>
    <div class="mobile_landscape_overlay_show">

      <div style="max-width: 600px; margin: 0 auto; padding: 20px; color: #ffffff;">
        <h1>Test Not Allowed</h1>
        <p>We're sorry, but the test is currently not allowed on mobile and tablet devices.</p>
        <p>If you have any questions or need further assistance, please contact the administrator.
        </p>
        <p>Open the link in the Chrome browser to continue the test.</p>

        <p>Contact us at <a href="mailto:support@hire3x.com" style="color: #ffffff;">support@hire3x.com</a>.</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.mobile_landscape_overlay_show {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #008eaf;
  height: 100vh;
}

.change_orientation_info_text {
  font-size: 16px;
  color: #fff;
  text-align: center;
}
</style>
